// Aquí se puede cambiar cuántos caracteres acepta el input de identificación:
export const MASK = [
    {
        id: 1,
        value: 'NNNN NNNNN NNNNN NNNN'
    },
    {
        id: 2,
        value: '#### ####'
    },
    {
        id: 3,
        value: '# #### ####'
    },
    {
        id: 4,
        value: '#### ##### ####'
    }
];

export const LOGIN_ERRORS = {
    "401": "Contraseña incorrecta, inténtalo de nuevo.",
    "422": "Identificación no registrada, regístrate.",
    "404": "Usuario no creado, intenta registrarte.",
};

export const REGISTER_ERRORS = {
    "409": "El correo ya se encuentra registrado, ingresa uno diferente.",
    "400": "El correo e identificación ya se encuentran registrados, recupera tu contraseña.",
    "418": "La identificación ya se encuentra registrada, ingresa una diferente."
}
export const MARCAS = [
    {
        key: 1,
        name: "Clavulin",
        countries: [1, 2, 3, 4,5, 6]
    },
    {
        key: 2,
        name: "Clavulin C/12",
        countries: [1, 2, 3, 4,5, 6]
    },
    /* {
        key: 2,
        name: "Hidrivag",
        countries: [5]
    },
    {
        key: 3,
        name: "Anuar T",
        countries: [1, 2, 3, 4, 5, 6]
    } */
];

export const FECHA_MINIMA_FACTURAS = new Date(2021, 6, 1);