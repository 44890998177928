<template>
  <div>
    <div class="container-fluid mt-4">
      <loading-overlay :active.sync="loading" :is-full-page="true" />
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row">
              <div class="col-9">
                <h3 class="mb-0 text-primary">Contraseña genérica:<br>clubdefacturas2022</h3>
              </div>
            </div>
          </template>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="pt-0">
              <base-input
                alternative
                v-mask="mask"
                v-model="query"
                type="search"
                prepend-icon="fas fa-id-card"
                placeholder="Ingresar identificación"
                rules="required"
              />
            </div>
            <div class="col-9">
              <base-button
                :disabled="validation"
                @click="updatePassword()"
                type="info"
                size="xl"
              >
                <span class="btn-inner--text">Restablecer contraseña</span>
              </base-button>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p class="card-category">
                <small>Recuerda revisar que la identificación esté correcta.</small>
              </p>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button
} from "element-ui";
import "flatpickr/dist/flatpickr.css";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { MASK } from "../../../utilities/constants";

export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button
  },
  data() {
    return {
      mask: MASK[0].value,
      query: null,
      loading: false,
      loading1: false,
      loading2: false,
    };
  },
  computed: {
    validation: function () {
      return (
        this.query == null || this.query == ""
      );
    }
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    async updatePassword() {
      this.loading = true;
      await this.$store.dispatch("pwd/reset", this.query.toUpperCase());
      const response = this.$store.getters["pwd/reset"];
      const username = response.data.user;
      
      if (response.status === 204) {
        swal.fire({
          title: `¡Atención!`,
          text: "No existe un usuario registrado con esta identificación.",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          icon: "question"
        });
      } else if (response.status === 500) {
        swal.fire({
          title: `Error`,
          text: "No se pudo buscar al usuario. Intenta más tarde.",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          icon: "error"
        });
      } else {
        swal.fire({
          title: `Éxito`,
          text: `La contraseña de ${username} fue regenerada exitosamente.`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          icon: "success"
        });
      }
      this.loading = false;
    }
  }
};
</script>
